import React from "react";
import "./cwords.css"; // Import the CSS file
import drawning from "./images/drawning.png"; // Assuming you have an image in your project

const Cwords = () => {
  return (
    <div className="c-container">
      <h1>Architects And Constructions</h1>
      {/* Section for Image */}
      <div className="c-image-section">
        <img src={drawning} alt="Interior Design" className="c-interior-image" />
      </div>

      {/* Section for Text */}
      <div className="c-text-section">
        <p>
          Get your architectural projects done right with VPR Industries. We are
          a leading architecture construction company with years of experience
          providing superior residential and commercial services. Let us bring
          your vision to life by using Contemporary trends in Architecture.
          Contemporary architecture goes beyond mere aesthetics, encompassing
          considerations of construction methods, operational efficiency, and
          future adaptability should its purpose change.
        </p>
        <p>
          Discover the Latest architectural designs and plans tailored perfectly
          to suit your residential needs, incorporating the latest trends in the
          field as we are the Leading{" "}
          <span>Architectural Construction Company in Nagercoil.</span>
        </p>
        <p>
          At the heart of our principles lies Environmental Responsibility,
          driving us to craft Sustainable Buildings that leave minimal impact on
          the environment through eco-friendly practices and innovative
          solutions. Our team of top-notch
          <span> Architects in Nagercoil </span> incorporates the latest
          construction advancements, including 3D printing and modular building
          systems, maintaining uncompromised quality and fast, efficient
          construction. For those seeking to renovate or build a home, we offer
          design ideas and tips to ensure that your home becomes a true
          reflection of your lifestyle and personality.
        </p>
        <p>
          Our motto centers on Affordability, Sustainability, and fast
          construction, all achieved with high-quality products. We specialize
          in creating small yet functional homes, using wood as the main design
          material, offering neutral choices for changing housing structures.
          Our designs prioritize uncluttered spaces, promoting a sense of calm
          and simplicity within the home, allowing homeowners to focus on their
          daily activities without distraction. We are renowned as the best
          Architectural Construction Company in Nagercoil.
        </p>
        <p>
          Embracing the future, our residences integrate Home Automation and
          Smart Technology as a primary concept. By employing automated curtains
          and smart lighting systems, energy consumption is optimized as lights
          are programmed to turn off when no one is present. This ensures a more
          energy-efficient and eco-friendly living environment for our
          residents.
        </p>
        <p>
          Feel free to customize your specific style of design to your homes or
          commercials as we are renowned as the BestInterior Design Company
          Nagercoil.
        </p>
      </div>
    </div>
  );
};

export default Cwords;
