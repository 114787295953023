import React from "react";
import "./luwords.css"; // Import the CSS file
import home from "./images/luxury.jpg"; // Assuming you have an image in your project

const Luwords= () => {
  return (
    <div className="lu-container">
      <h1>Luxury Home Construction</h1>
      {/* Section for Image */}
      <div className="lu-image-section">
        <img src={home} alt="Interior Design" className="lu-interior-image" />
      </div>

      {/* Section for Text */}
      <div className="lu-text-section">
        <p>
          Most Homeowners are seeking luxury home construction not solely for
          comfort but primarily for enhanced security. We assure you that we
          will prioritize the safety and credibility of your residents by
          incorporating cutting-edge features that offer unparalleled security
          and convenience as we are the prominent
          <span> Luxury Home Construction Company in Nagercoil.</span>
        </p>
        <p>
          Our integration of advanced smart home systems enables residents to
          control lighting and security using their smartphones or voice
          commands through remote control. We have also introduced innovative
          Home Automation Services, catering separately to window blinds and
          self-cleaning services as we have Luxury Builders in Nagercoil that
          will replicate in your house what you dreamt of.
        </p>
        <p>
          With these home automation systems, homeowners have complete control
          over various aspects, from adjusting temperatures before arriving home
          on a sunny day to monitoring their child's activities in another room.
          Additionally, we provide Wi-Fi-enabled cameras to keep your
          technologically advanced home under constant surveillance. By offering
          such features, we aim to provide homebuyers with unparalleled comfort
          and ease of living at their fingertips.
        </p>
        <p>
          In our luxury residential construction projects, we do
          <span>Luxury Builders in Nagercoil.</span> We understand the
          significance of architecture and home décor in creating a unique
          living experience. As the leading Luxury Home construction company in
          Nagercoil, we tailor our designs to cater to consumers' desires,
          incorporating contemporary designs and personalized plans.
        </p>
        <p>
          Homebuyers consistently seek high-end amenities and top-quality
          construction materials for their dream homes. We ensure earthquake
          resistance, top-quality domestic modular kitchens, fire-resistant
          materials, vitrified tiling, and durable flooring to provide residents
          with the highest quality of life and a consistently peaceful
          experience.
        </p>
        <p>
          Safety is our top priority, and we take it seriously in our premium
          luxury projects as we are the Luxury Home Construction Company in
          Nagercoil. We employ the latest electronic monitoring and surveillance
          systems, including CCTV cameras, 24-hour panic buttons on every floor
          to alert security guards, fire safety devices with sprinklers, gas
          leakage detectors, and motion sensors.
        </p>
      </div>
    </div>
  );
};

export default Luwords;
