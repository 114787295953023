import React from "react";
import "./choice.css";
import Cwords from "./cwords";


function Choice() {
  return (
    <div>
      <div className="choice-banner">
        <div className="choice-overlay">
          <h1>Get Your Dream Home in Nagercoil with VPR Architects & Constructions</h1>
          <hr className="choice-underline" />
        </div>
      </div>
      <div className="col-12 mb-4">
        <Cwords />
      </div>
    </div>
  );
}

export default Choice;
