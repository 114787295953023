import React from "react";
import "./int.css";
import intt from "./images/int3.jpg";
import ban from "./images/int-banner.jpg";

function Interval() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={intt} alt="About " className="full-width-image" />
        <div className="about-overlay">
          <h1>Interior</h1>
          <hr className="about-underline" />
        </div>
      </div>

      <div>
        <h3 className="ren-content">
          ---------- Transforming Spaces with Exceptional Interior Design in
          Nagercoil ----------
        </h3>
      </div>

      <div className="about-content-container">
        <div className="image-container">
          <img src={ban} alt="About" className="img-about-int" />
        </div>
        <div className="about-text-container">
          <p className="about-paragraph-int">
            VPR Architects & Constructions stands as the premier interior design
            firm in Nagercoil, specializing in both commercial and residential
            design and décor. With nearly 10 years of expertise in creating
            stunning interiors, we understand the growing desire for homes,
            offices, flats, malls, and restaurants to reflect elegance and
            style. Our talented designers carefully curate the ideal mix of
            materials—flooring, wall treatments, and fabrics—to deliver a
            captivating aesthetic. Don’t look elsewhere; one call to VPR
            Architects & Constructions will connect you with the finest interior
            design experts in your area.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Interval;
