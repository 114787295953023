import React from "react";
import "./banner.css";
function Banner() {
  return (
    <div className="banner">
      <div className="overlay1">
        <h1>Blog</h1>
        <hr className="underline" />
      </div>
    </div>
  );
}

export default Banner;