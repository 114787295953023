import React from "react";
// Inside your JavaScript or JSX file (like App.js or CarouselComponent.js)
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JS

import "./App.css";
import arc1 from "./images/archi-1.jpg";
import con1 from "./images/construction1.png";
import int1 from "./images/interior.jpg";
import slide1 from "./images/front-one.jpg";
import slide2 from "./images/car2.jpg";
import slide3 from "./images/car1.jpg";
import "@fontsource/raleway";
import { Routes, Route } from "react-router-dom"; // Import Routes and Route
import Header from "./header"; // Import Header
import Footer from "./footer"; // Import Footer (assuming you have one)
import About from "./about";
import Service from "./service";
import StatsSection from "./counts.js"; // Import the StatsSection component
import Provide from "./provide.js";
import FeatureTitle from "./feature.js";
import Blog from "./blog.js";
import Reframe from "./link.js";
import Choice from "./choice.js";
import Luxury from "./luxury.js";
import Contact from "./contact.js";
import Projects from "./project.js";
import Review from "./review.js";

import Architecture from "./architecture.js";
import Construction from "./construction.js";
import Renovation from "./renovation.js";
import Interval from "./int.js";
import Okay from "./approval.js";
import Layer from "./mainbl.js";

// Home component with your existing App content
function Home() {
  return (
    <div className="App">
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={slide1} className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src={slide2} className="d-block w-100" alt="Slide 2" />
          </div>
          <div className="carousel-item">
            <img src={slide3} className="d-block w-100" alt="Slide 3" />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <br />
      <br />
      <h1 className="title">Best Construction & Architect Company</h1>
      <p className="paragraph">
        VPR Architects & Constructions is a premier construction company based
        in Nagercoil, Tamil Nadu, renowned for its exceptional building
        solutions and customer-focused approach. We excel in designing and
        constructing residential and commercial properties, including houses,
        commercial buildings, and landscapes, throughout Nagercoil, Marthandam,
        Kanyakumari, and Tamil Nadu. We prioritize your input through detailed
        checklists and flexible meeting arrangements to ensure that your vision
        for your dream home is realized. Our expertise in state-of-the-art
        building designs allows us to create homes that perfectly fit your needs
        and budget. As a leading design-build firm, we work closely with clients
        from the initial design phase to final construction, ensuring their
        ideas become reality.
      </p>
      <div className="my-4">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src={arc1} className="card-img-top" alt="Architect" />
              <div className="card-body">
                <h5 className="card-title">Architect</h5>
                <p className="card-text">
                  VPR Architects & Constructions is one of the leading
                  architecture firms based in Nagercoil, Tamil Nadu. The goal of
                  the architecture design firm is to enhance people's lifestyles
                  by providing innovative house designs for projects. Contact us
                  today to embark on your journey to create the home of your
                  dreams.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src={con1} className="card-img-top" alt="Construction" />
              <div className="card-body">
                <h5 className="card-title">Construction</h5>
                <p className="card-text">
                  One of Nagercoil's best luxury home builders, VPR Architects &
                  Constructions offers luxurious residences to its most
                  discerning clients. We're going to go over some of the main
                  advantages of having a luxury home. Excellent services, a
                  pleasant atmosphere, a large space, luxurious extras, and
                  creating wonderful memories.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src={int1} className="card-img-top" alt="Interior" />
              <div className="card-body">
                <h5 className="card-title">Interior</h5>
                <p className="card-text">
                  VPR Architects & Constructions is one of the leading interior
                  design companies in Nagercoil, Tamil Nadu. In every interior
                  we design and build, we assist you to enhance your living by
                  delivering beautifully designed premium and durable interior
                  spaces which enable your life to be easier and more efficient.
                </p>
              </div>
            </div>
          </div>
          {/* StatsSection positioned next to the cards */}
          <div className="col-12 mb-4">
            <StatsSection />
          </div>
          <div className="col-12 mb-4">
            <Provide />
          </div>
          <div className="col-12 mb-4">
            <Review />
          </div>
          <div className="col-12 mb-4">
            <FeatureTitle />
          </div>
        </div>
        <div className="col-12 mb-4">
          <Blog />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home page */}
        <Route path="/about" element={<About />} /> {/*About page */}
        <Route path="/service" element={<Service />} />
        {/*Service page*/}
        <Route path="/contact" element={<Contact />} />
        {/*Contact page*/}
        <Route path="/projects" element={<Projects />} />
        {/*Contact page*/}
        <Route path="/link" element={<Reframe />} />
        {/*Interior  page*/}
        <Route path="/choice" element={<Choice />} />
        {/*Choice page*/}
        <Route path="/luxury" element={<Luxury />} />
        {/*Luxury page*/}
        <Route path="/architecture" element={<Architecture />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/renovation" element={<Renovation />} />
        <Route path="/interval" element={<Interval />} />
        <Route path="/okay" element={<Okay />} />
        <Route path="/main" element={<Layer />} />
        {/* Add more routes here */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
