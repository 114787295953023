import React, { useState } from 'react';
import '@fontsource/raleway'; 
import './provide.css'; // Make sure to import the CSS file where the `.name` class is defined
import arr from './images/ar 3.jpg';
import build from './images/build.jpg';
import ren from './images/ren.jpg';
import int from './images/int 2.jpg';
import appp from './images/app.jpg';

function Provide() {
  const [visibleSection, setVisibleSection] = useState(null);

  const handleButtonClick = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  return (
    <div className="container">
      <div className='name'>Construction Company</div>
      <div className='client'>We Provide Best Services For Our Clients</div>
      <div className='buttons'>
        <button
          className={visibleSection === 'architectural' ? 'active' : ''}
          onClick={() => handleButtonClick('architectural')}
        >
          Architectural Design
        </button>
        <button
          className={visibleSection === 'construction' ? 'active' : ''}
          onClick={() => handleButtonClick('construction')}
        >
          Construction
        </button>
        <button
          className={visibleSection === 'renovation' ? 'active' : ''}
          onClick={() => handleButtonClick('renovation')}
        >
          Renovation
        </button>
        <button
          className={visibleSection === 'interior' ? 'active' : ''}
          onClick={() => handleButtonClick('interior')}
        >
          Interior Design
        </button>
        <button
          className={visibleSection === 'approvals' ? 'active' : ''}
          onClick={() => handleButtonClick('approvals')}
        >
          Approvals
        </button>
      </div>
      <div className='sections'>
        {visibleSection === 'architectural' && (
          <div>
            <div className='image-text-container'>
              <img src={arr} alt='Architectural Design' className='image' />
              <ul>
                <li>At VPR Architects & Constructions, we design beautiful, budget-friendly homes in Nagercoil, Tamil Nadu.</li><li>Our focus on quality and affordability ensures customer satisfaction.</li> <li> We use top materials and advanced technology to create ideal spaces for homes and workplaces, backed by a proven track record in exceptional architectural projects.</li><li>We bring out innovation in design</li>
              </ul>
            </div>
          </div>
        )}

        {visibleSection === 'construction' && (
         <div>
            <div className='image-text-container'>
              <img src={build} alt='Construction Service' className='image' />
              <ul>
                <li>At VPR Architects & Constructions, we consistently meet high-quality standards through superior execution.</li><li> If you're looking to build your dream house, engage with a luxury home builder who understands your vision and can bring it to life.</li><li>Nagercoil boasts some of the finest luxury home builders, ideal for starting your search.</li> <li>These builders use top-quality materials and pay meticulous attention to detail, ensuring your dream home is built to perfection.</li> 
             </ul>
            </div>
          </div>
        )}

        {visibleSection === 'renovation' && (
        <div>
            <div className='image-text-container'>
              <img src={ren} alt='Renovation Service' className='image' />
              <ul>
                <li>VPR Architects & Constructions is a top provider of home renovation services in Nagercoil.</li><li> We handle all aspects of renovation, including construction, interior design, landscaping, painting, and more.</li><li>Our team delivers high-quality remodeling with a commitment to excellence and a guarantee on all our renovation work.</li> <li>We visualize the plan before the work begins.</li>
              </ul>
            </div>
          </div>
        )}

        {visibleSection === 'interior' && (
          <div >
            <div className='image-text-container'>
              <img src={int} alt='Interior Design Service' className='image' />
              <ul>
                <li>At VPR Architects & Constructions, we deliver high-quality, long-lasting interiors.</li><li> As a leading interior design company in Nagercoil, Tamil Nadu, we offer a fully customizable suite of services for both residential and commercial projects.</li><li> Our team ensures excellence at every stage, making us one of the best choices for commercial interior design in the area.</li><li>We utilize space for a seamless interior outlook.</li>
              </ul>
            </div>
          </div>
        )}
        {visibleSection === 'approvals' && (
          <div >
            <div className='image-text-container'>
              <img src={appp} alt='Approvals Service' className='image' />
              <ul>
                <li>We have logistic services in the nation, whether it is freight transportation, supply chain solutions, warehousing and distribution, customer resource area services, customs, security and insurance, temperature controlled logistics.</li><li>Our experts help to prepare the required documents.</li>
<li> We help with building codes and regulations for your plans.</li>
<li>Our team helps to reduce the risk of Penalties.</li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Provide;
