import React from "react";
import "./review.css"; // Import the associated CSS
import user from "./images/user.png";
const Review = () => {
  return (
    <div className="testimonials-page">
      <h1>What People Said</h1>
      <p>We Have More Happy Clients And These Are Some Of Our Happy Clients.</p>

      <div className="testimonials-container">
        <div className="testimonial">
          <div className="quote">
            <p>
              " I’ve had the privilege to work with VPR Architects &
              Constructions . They have provided an excellent team of
              well-established and experienced professionals. Their work on the
              VLR Residency Project was outstanding as that was their
              professionalism, attentiveness, attitude, and performance."
            </p>
          </div>
          <div className="author">
            <img src={user} alt="avatar" className="avatar" />
            <div className="author-info">
              <h4>Founder of VLR Residency</h4>
              <p>@Vetrivelan</p>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="quote">
            <p>
              "I would like to express my gratitude to VPR Architects &amp;
              Constructions company for their Exceptional Services. They have
              recently completed our home project and the results are
              remarkable. They were reliable and proactive in addressing any
              concerns during the construction process. I highly recommend them
              for any construction Needs."
            </p>
          </div>
          <div className="author">
            <img src={user} alt="avatar" className="avatar" />
            <div className="author-info">
              <h4>Founder of Gnanadeepam Coconut Oil Mil</h4>
              <p>@gnana_pattu</p>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="quote">
            <p>
              After researching many construction companies, I decided to go
              with VPR Architects & Constructions . It was a wise Choice. Their
              team demonstrated exceptional professionalism, and knowledge, and
              delivering top-notch quality was evident in every detail. I would
              recommend VPR in the future also.
            </p>
          </div>
          <div className="author">
            <img src={user} alt="avatar" className="avatar" />
            <div className="author-info">
              <h4>Dr. Madhavan</h4>
              <p>@madhavan</p>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="quote">
            <p>
              "I would like to express my gratitude to VPR Architects &amp;
              Constructions company for their Exceptional Services. They have
              recently completed our home project and the results are
              remarkable. They were reliable and proactive in addressing any
              concerns during the construction process. I highly recommend them
              for any construction Needs."
            </p>
          </div>
          <div className="author">
            <img src={user} alt="avatar" className="avatar" />
            <div className="author-info">
              <h4>Mr. Padmanabhan</h4>
              <p>@padmanabhan</p>
            </div>
          </div>
        </div>

        {/* Add more testimonials similarly */}
      </div>
    </div>
  );
};

export default Review;