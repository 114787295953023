import React from "react";
import "./form.css";
import location from "./images/location.png";
import email from "./images/email.png";
import phone from "./images/phone-one.png";
import Swal from "sweetalert2";

function Contactus() {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "df88832f-8a85-4cb2-bf24-4f88777088da");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        Swal.fire({
          title: "Success",
          text: "Message sent successfully",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: result.error || "Something went wrong",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to send message. Please try again later.",
        icon: "error",
      });
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <div className="underline"></div>
        <div className="info-item">
          <img src={location} alt="location" className="icon" />
          <div>
            <h4>Location</h4>
            <p>
              19/8, Nadukattu Essaki Amman Koil street, Arul Nagar Rd,
              Nagercoil-629001.
            </p>
          </div>
        </div>
        <div className="info-item">
          <img src={email} alt="email" className="icon" />
          <div>
            <h4>Email</h4>
            <p>vprjeganath@gmail.com</p>
          </div>
        </div>
        <div className="info-item">
          <img src={phone} alt="phone" className="icon" />
          <div>
            <h4>Call Us</h4>
            <p>+91 93853 85985</p>
            <p>04652 - 236 240</p>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <h2>Contact Form</h2>
        <div className="underline"></div>
        <form onSubmit={onSubmit}>
          <div className="form-item">
            <input type="text" name="name" placeholder="Name" required />
          </div>
          <div className="form-item">
            <input type="email" name="email" placeholder="Email" required />
          </div>
          <div className="form-item">
            <input type="text" name="subject" placeholder="Subject" required />
          </div>
          <div className="form-item">
            <textarea
              name="message"
              placeholder="Message"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Contactus;
