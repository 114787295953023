import React from "react";
import "./construction.css";
import arch from "./images/construction2.jpg";
import write from "./images/con-write.jpg";

function Construction() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={arch} alt="About " className="full-width-image-con" />
        <div className="about-overlay">
          <h1>Construction</h1>
          <hr className="about-underline" />
        </div>
      </div>
      <div>
        <h3 className="con-content">
          ---------- Crafting Your Ideal Residence with Our Premier Home
          Construction Firm in Nagercoil ----------
        </h3>
      </div>
      <div className="con-container">
        <div className="image-con">
          <img src={write} alt="img" className="img-con" />
        </div>
        <p className="con-para">
          From concept inception to precise architectural schematics, our team
          at VPR Architects & Constructions in Nagercoil collaborates with you
          to rejuvenate your space. As a premier architectural firm in Tamil
          Nadu, we specialize in crafting bespoke residential, commercial, and
          public designs. Our approach integrates personalized, functional, and
          aesthetically refined solutions, ensuring each project reflects the
          client's vision while adhering to high standards of durability and
          visual appeal.
        </p>
      </div>
    </div>
  );
}

export default Construction;
