import React from "react";
import "./link.css";
import Intwords from "./intwords";

function Reframe() {
  return (
    <div>
      <div className="interior-banner">
        <div className="interior-overlay">
          <h1>How to Create the Perfect Interior Design Look for Your Space</h1>
          <hr className="interior-underline" />
        </div>
      </div>
      <div className="col-12 mb-4">
        <Intwords />
      </div>
    </div>
  );
}

export default Reframe;
