import React from "react";
import "./approval.css";
import appro from "./images/approved.jpg";
import num from "./images/green-approval.jpg";

function Okay() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={appro} alt="Approval" className="full-width-image" />
        <div className="about-overlay">
          <h1>Approval</h1>
          <hr className="about-underline" />
        </div>
      </div>

      <div>
        <h3 className="h3-content">---------- Approvals-Building ----------</h3>
      </div>

      <div className="about-content-container">
        <div className="image-container">
          <img src={num} alt="Green Approval" className="img-about" />
        </div>
        <div className="about-text-container">
          <p className="about-paragraph">
            We offer comprehensive support in obtaining the necessary permits
            and licenses for your projects. Whether you are planning a
            construction project or starting a business, our team will guide you
            through the application process in a timely manner. Our goal is to
            simplify the process and provide you the guidance to navigate the
            approval procedures efficiently and effectively.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Okay;
