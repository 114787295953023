import React from "react";
import "./renovation.css";
import ren from "./images/renovation.jpg";
import rework from "./images/reno2.jpg";

function Renovation() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={ren} alt="About " className="full-width-image-ren" />
        <div className="about-overlay">
          <h1>Renovation</h1>
          <hr className="about-underline" />
        </div>
      </div>

      <div>
        <h3 className="ren-content">
          ---------- Leading Home Renovation and Remodeling Expert in Nagercoil
          ----------
        </h3>
      </div>

      <div className="ren-container">
        <div className="image-container">
          <img src={rework} alt="img" className="img-ren" />
        </div>
        <div className="ren-para-container">
          <p className="ren-para">
            Your home reflects your unique style and taste. Our residential
            renovation services range from custom kitchen remodels to complete
            home transformations. As Nagercoil's leading renovation and
            remodeling company, we bring expertise to residential, commercial,
            and industrial projects alike. With a focus on thoughtful space
            planning and design, our team enhances layout and functionality to
            create seamless, efficient environments.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Renovation;
