import React from "react";
import "./project.css";
import Figure from "./figure"; // Ensure this component exists and is correctly imported.

function Project() {
  return (
    <div>
      {/* Project Banner Section */}
      <div className="project-banner">
        <div className="project-overlay">
          <h1>Project</h1>
          <hr className="project-underline" />
        </div>
      </div>

      {/* Figure Section */}
      <div className="col-12 mb-4">
        <Figure /> {/* Ensure the Figure component is correctly defined and working */}
      </div>
    </div>
  );
}

export default Project;
