import React, { useState } from "react";
import "./header.css";
import logo from "./images/logo.png";
import { NavLink } from "react-router-dom";
import Phone from "./images/phone-one.png";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navActive, setNavActive] = useState(false);

  const handleMouseEnter = () => setDropdownOpen(true);
  const handleMouseLeave = () => setDropdownOpen(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo-image" />
      </div>
      <div className="menu-icon" onClick={toggleNav}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav className={`nav ${navActive ? "active" : ""}`}>
        <ul className={`nav-list ${navActive ? "active" : ""}`}>
          <li className="nav-item">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              About
            </NavLink>
          </li>
          <li
            className="nav-item dropdown"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink
              to="/service"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Services
            </NavLink>
            {dropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <NavLink to="/architecture">Architecture</NavLink>
                </li>
                <li>
                  <NavLink to="/construction">Construction</NavLink>
                </li>
                <li>
                  <NavLink to="/renovation">Renovation</NavLink>
                </li>
                <li>
                  <NavLink to="/interval">Interior</NavLink>
                </li>
                <li>
                  <NavLink to="/okay">Approvals</NavLink>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-item">
            <NavLink
              to="/main"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Blog
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/projects"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Projects
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={`phone-icon-container ${navActive ? "active" : ""}`}>
        <button className="phone-button">
          <img src={Phone} alt="Phone Icon" className="phone-icon" />
          <span className="phone-number">
            <a href="tel:+9655230888">9655230888</a>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
