import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";

const Blog = () => {
  return (
    <div className="blog-container">
      <h2>Blog</h2>
      <div className="blog-grid">
        <div className="blog-card">
          <h3>How to Create the Perfect Interior Design look for your Space</h3>
          <p>
            Do you need your home or office space to be contemporary and modern?
            Just switch to VPR Architects and Constructions,
            <span> an Interior Designing Company in Nagercoil.</span> Enhance
            your space with us for a perfect interior design look by embracing
            the proven contemporary designs of interior decorum.
          </p>
        
<Link to="/link">
  <button>READ MORE</button>
</Link>

        </div>

        <div className="blog-card">
          <h3>
            Why VPR Architects And Constructions is the Best Choice for Your
            Dream Project
          </h3>
          <p>
            Here are some of the reasons why VPR Architects & Constructions is
            the <span>Best construction company in Nagercoil.</span> They are
            renowned for their exceptional quality of work and have a proven
            track record of on-time delivery.Their attention to detail ensures
            every project is executed with precision and care.
          </p>
          <Link to="/choice">
  <button>READ MORE</button>
</Link>
        </div>

        <div className="blog-card">
          <h3>Most Innovative Features in Your Luxury Home Construction</h3>
          <p>
            Homeowners choose luxury construction for both comfort and heightened security. We ensure the safety of your residents with cutting-edge features for exceptional security and convenience, establishing us as the top choice in luxury home building.
            <span> Luxury Home Construction Company in Nagercoil.</span>
          </p>
          <Link to="/luxury">
            <button>READ MORE</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blog;
