import React from "react";
import "./footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-address">
          <p>19/8,</p>
          <p>Nadukattu Essaki Amman Koil Street,</p>
          <p>Arul Nagar Rd,</p>
          <p>Nagercoil-629001.</p>
        </div>
        <div className="footer-contact">
          <p>Phone: +91 9655230888</p>
          <p>Landline: 04652 - 236 240</p>
          <p>Email: vprjeganath@gmail.com</p>
          <p>Active Hours: 9.00 AM to 7.00 PM</p>
        </div>
        <div className="footer-locations">
          <p>📍 Tirunelveli</p>
          <p>📍 Nagercoil</p>
          <p>📍 Madurai</p>
          <p>📍 Coimbatore</p>
          <p>📍 Chennai</p>
          <p>📍 Kovilpatti</p>
          <p>📍 Nazareth</p>
          <p>📍 Ambasamudram</p>
          <p>📍 Valliyur</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          © 2024 VPR Architects & Constructions. Digital Partner Pabok
          Technologies.
        </p>
      </div>
    </div>
  );
};

export default Footer;
