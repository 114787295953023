import React from 'react';
import  './count.css';
const Counts = () => {
  const statsData = [
    { icon: 'fas fa-home', number: '30+', label: 'Residential Projects' },
    { icon: 'fas fa-briefcase', number: '20+', label: 'Commercial Projects' },
    { icon: 'fas fa-user-tie', number: '10+', label: 'Years Experienced' },
    { icon: 'fas fa-smile', number: '100%', label: 'Satisfied Clients' },
  ];

  return (
    <section className="stats-section">
      <div className="stats-container">
        {statsData.map((stat, index) => (
          <div key={index} className="stat-card">
            <i className={`${stat.icon} stat-icon`}></i>
            <h2>{stat.number}</h2>
            <p>{stat.label}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Counts;
