import React from "react";
import "./architecture.css";
import arc from "./images/arch-b1.jpg";
import ima from "./images/indianarch.jpg";

function Architecture() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={arc} alt="About" className="full-width-image-arc" />
        <div className="about-overlay">
          <h1>Architecture</h1>
          <hr className="about-underline" />
        </div>
      </div>
      <div>
        <h3 className="archi-content">
          ---------- Premier Architectural and Construction Firm in Nagercoil
          ----------
        </h3>
      </div>
      <div className="content-container">
        {/* Image comes first */}
        <div className="image-container">
          <img src={ima} alt="img" className="img-archi" />
        </div>
        <div className="divider"></div>
        <p className="arc-para">
          From concept inception to precise architectural schematics, our team
          at VPR Architects & Constructions in Nagercoil collaborates with you
          to rejuvenate your space. As a premier architectural firm in Tamil
          Nadu, we specialize in crafting bespoke residential, commercial, and
          public designs. Our approach integrates personalized, functional, and
          aesthetically refined solutions, ensuring each project reflects the
          client's vision while adhering to high standards of durability and
          visual appeal. Rely on us to transform your architectural aspirations
          into reality with unparalleled expertise.
        </p>
      </div>
    </div>
  );
}

export default Architecture;
