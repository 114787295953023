import React from "react";
import "./intwords.css"; // Import the CSS file
import Interior from "./images/Intwordsimg.jpg"; // Assuming you have an image in your project

const Intwords = () => {
  return (
    <div className="container">
      <h1>Interior Designing Company in Nagercoil</h1>
      {/* Section for Image */}
      <div className="image-section">
        <img src={Interior} alt="Interior Design" className="interior-image" />
      </div>

      {/* Section for Text */}
      <div className="text-section">
        <p>
          Do you need your home or office space to be contemporary and Modern?
          Just switch to VPR Architects and Constructions, an
          <span> Interior Designing Company in Nagercoil.</span> Enhance your
          space with us for a perfect interior design look by embracing the
          proven contemporary designs of interior decorum.
        </p>
        <p>
          In the Modern Era, the love for Interior design has captivated
          everyone making it a must-have for homes, offices, flats, malls, and
          restaurants alike. Increasing their desire to bring natural effects
          indoors has led to a growing trend known as Biophilic design. It
          incorporates natural elements, such as plants, natural light, and
          organic textures, into interior spaces. Living green walls, large
          windows, and nature-inspired artwork create a sense of connection with
          nature, promoting well-being and productivity. These home décor trends
          will give you an idea about all sorts of decorum phases as we are the
          <span>Interior Decoration Company in Nagercoil.</span>
        </p>
        <p>
          The importance of being eco-conscious has taken center stage, playing
          a vital role in shaping our choices and decisions. Sustainable
          materials like bamboo, reclaimed wood, and recycled materials are
          gaining popularity. Eco-friendly practices such as energy-efficient
          lighting and water-saving fixtures are also being integrated into
          design plans. Embracing sustainability not only reduces our
          environmental impact but also creates a healthier and more comfortable
          environment.
        </p>
        <p>
          A refreshing twist comes upon for furnishing the space. We see a shift
          towards warm neutrals, soft textures, and curated collections.
          Incorporating artisanal pieces, handmade ceramics, and unique textiles
          adds character and depth to minimalist spaces, making them more
          inviting and personal. Next is all about making a statement with color
          and pattern. Vibrant hues like emerald green, deep blue, and mustard
          yellow are used to create focal points and add drama to rooms.
          Experimenting with bold wallpaper, geometric patterns, and graphic
          prints on furniture and accessories brings energy and visual interest
          to any space.
        </p>
        <p>
          Feel free to customize your specific style of design to your homes or
          commercials as we are renowned as the Best Interior Design Company
          Nagercoil.
        </p>
      </div>
    </div>
  );
};

export default Intwords;
