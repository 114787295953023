import React from "react";
import "./luxury.css";
import Luwords from "./luwords";


function Luxury() {
  return (
    <div>
      <div className="luxury-banner">
        <div className="luxury-overlay">
          <h1>Most Innovative Features in Your Luxury Home Construction</h1>
          <hr className="luxury-underline" />
        </div>
      </div>
      <div className="col-12 mb-4">
       <Luwords/>
      </div>
    </div>
  );
}

export default Luxury;
