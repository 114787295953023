import React from "react";
import "./service.css";
import kitchen from "./images/service.jpg";
import int from "./images/int 1.jpg";
import Td from "./images/3d.jpg";
import plan from "./images/plan.jpg";
import ar from "./images/ar 2.jpg";
import con from "./images/con.jpg";
import app from "./images/approval.jpg";
import re from "./images/re.jpg";
function Service() {
  return (
    <div className="kitchen-page">
      <div className="kitchen-container">
        <img src={kitchen} alt="Kitchen" className="full-width-image" />
        <div className="kitchen-overlay">
          <h1>Services</h1>
          <hr className="kitchen-underline" />
        </div>
      </div>

      <h3 className="our-service">
        --------------- WHAT WE DELIVER -----------------
      </h3>
      <br></br>
      <div className="card-container">
        <div className="card">
          <img src={int} alt="Service" className="card-image" />
          <h3 className="card-title">Interior</h3>
        </div>
        <div className="card">
          <img src={plan} alt="Service" className="card-image" />
          <h3 className="card-title">House Planning</h3>
        </div>
        <div className="card">
          <img src={Td} alt="Service" className="card-image" />
          <h3 className="card-title">3D Animation</h3>
        </div>

        <div className="card">
          <img src={ar} alt="Service" className="card-image" />
          <h3 className="card-title">Architect</h3>
        </div>
        <div className="card">
          <img src={con} alt="Service" className="card-image" />
          <h3 className="card-title">Construction</h3>
        </div>
        <div className="card">
          <img src={app} alt="Service" className="card-image" />
          <h3 className="card-title">Approvals</h3>
        </div>
        <div className="card">
          <img src={re} alt="Service" className="card-image" />
          <h3 className="card-title">Renovation</h3>
        </div>
      </div>
    </div>
  );
}
export default Service;
