import React from 'react';
import '@fontsource/raleway'; 
import './feature.css'; // Assuming your CSS is in this file
import fone from './images/f1.webp';
import ftwo from './images/f2.jpg';
import fthree from './images/f3.webp';
import ffour from './images/f4.jpg';

const FeatureTitle = () => {
  return (
    <div className="title-container">
      <h1 className="title">Curated Creations</h1>
      <div className="dash"></div> {/* Dash line */}
      <h4 className='subtitle'>Where Every Detail Reflects Perfection</h4>
    
      <div className="image-grid">
        <div className="image-wrapper">
          <img src={fone} alt="Image 1" />
          <div className="hover-text">Perumal Nagar</div>
        </div>
        <div className="image-wrapper">
          <img src={ftwo} alt="Image 2" />
          <div className="hover-text">Manisha Plaza</div>
        </div>
        <div className="image-wrapper">
          <img src={fthree} alt="Image 3" />
          <div className="hover-text">Harish Vadiveeswaram</div>
        </div>
        <div className="image-wrapper">
          <img src={ffour} alt="Image 4" />
          <div className="hover-text">Dr Abdul Kalam International School</div>
        </div>
      </div>
    </div>
  );
};

export default FeatureTitle;
