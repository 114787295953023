import React from "react";
import "./figure.css";
import home1 from "./images/perumal.jpg";
import home2 from "./images/mathan.jpg";
import home3 from "./images/arumugam.jpg";
import home4 from "./images/vishnu.jpg";
import home5 from "./images/michael.jpg";
import home6 from "./images/kannan.jpg";
import home7 from "./images/selvam.jpg";
import home8 from "./images/rafeek.jpg";
import home9 from "./images/alagappa.jpg";
import home10 from "./images/residental.jpg";
import home11 from "./images/safi.jpg";
import home12 from "./images/kingsly.jpg";
function Figure() {
  return (
    <div className="card-container">
      <div className="card">
        <img src={home1} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr. Chidambararam - Perumal Nagar</h3>
      </div>
      <div className="card">
        <img src={home2} alt="Service Image" className="card-image" />
        <h3 className="card-title">Dr. Mathan - Nagercoil</h3>
      </div>
      <div className="card">
        <img src={home3} alt="Service Image" className="card-image" />
        <h3 className="card-title">Dr. Arumugam - Thirunelveli</h3>
      </div>

      <div className="card">
        <img src={home4} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr.Vishnu Sharma - Meenakshi Garden</h3>
      </div>
      <div className="card">
        <img src={home5} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr. Michael - NGO Colony</h3>
      </div>
      <div className="card">
        <img src={home6} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr. Kannan - Meenakshi Garden</h3>
      </div>
      <div className="card">
        <img src={home7} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr.P. Selvam - Chunkankadai</h3>
      </div>
      <div className="card">
        <img src={home8} alt="Service Image" className="card-image" />
        <h3 className="card-title">
          Mr. Rafeek Residence Apartment - Nagercoil
        </h3>
      </div>
      <div className="card">
        <img src={home9} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr. Alagappa Pillai - Kottaram</h3>
      </div>
      <div className="card">
        <img src={home10} alt="Service Image" className="card-image" />
        <h3 className="card-title">Residential House</h3>
      </div>
      <div className="card">
        <img src={home11} alt="Service Image" className="card-image" />
        <h3 className="card-title">Safi Mahal</h3>
      </div>
      <div className="card">
        <img src={home12} alt="Service Image" className="card-image" />
        <h3 className="card-title">Mr. Kingsly - Maravankudieruppu</h3>
      </div>
    </div>
  );
}

export default Figure;
