import React from "react";
import "./contact.css";
import Contactus from "./form";
import Map from './map';

function Contact() {
  return (
    <>
      <div className="contact-banner">
        <div className="contact-overlay">
          <h1>Contact</h1>
          <hr className="contact-underline" />
        </div>
      </div>
      <div className="col-12 mb-4">
        <Contactus />
      </div>
      <div className="col-12 mb-4">
        <Map />
      </div>
    </>
  );
}

export default Contact;
