import './map.css';

function Map() {
  return (
    <>
       <div className='center-div'>
    <h4 className='come'>Come on Over to Our Office</h4>
</div>

      <div className="responsive-map">
        <iframe
          title="VPR Architects & Constructions Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.2381251599695!2d77.41205587313358!3d8.178749801722155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f0dd1644f8d9%3A0x89d896ad73ee7ee9!2sVPR%20ARCHITECTS%20%26%20CONSTRUCTIONS!5e0!3m2!1sen!2sin!4v1726032290877!5m2!1sen!2sin"
          width="600"
          height="450"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}

export default Map;
