import React from "react";
import "./about.css";
import about from "./images/about.jpg";

function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        <img src={about} alt="About" className="full-width-image" />
        <div className="about-overlay">
          <h1>About Us</h1>
          <hr className="about-underline" />
        </div>
      </div>

      <h3 className="h3-content">Who We Are</h3>
      <p className="about-paragraph">
        VPR Architects & Constructions is a renowned and reputed construction
        company in Nagercoil. We do all types of construction works, reputed in
        providing all our customers functional & aesthetically pleasing
        apartments, complemented by grandiose exteriors built using the latest
        innovative technology along with a wholesome of essential amenities. VPR
        Architects & Constructions is headed and professionally managed by{" "}
        <span className="highlight-name">
          Er. V.P. Ram Jeganath M.E., M.B.A.
        </span>{" "}
        (M.E - Construction Engineering and Management Anna University Chennai &
        M.B.A – Marketing Management) with over 10 years of rich experience in
        the areas of project planning and management, site administration,
        quantity surveying, and resource management. Our team consists of highly
        trained and experienced professionals who are dedicated to delivering
        exceptional results. From architects and engineers to project managers
        and skilled laborers, we have assembled a team of experts who are
        committed to excellence in every aspect of construction.
      </p>
    </div>
  );
}

export default About;
